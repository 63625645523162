"use strict";

function _typeof(obj) {
  "@babel/helpers - typeof";

  if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {
    _typeof = function _typeof(obj) {
      return typeof obj;
    };
  } else {
    _typeof = function _typeof(obj) {
      return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };
  }
  return _typeof(obj);
} // http://www.paulirish.com/2009/throttled-smartresize-jquery-event-handler/

(function ($, sr) {
  var debounce = function debounce(func, threshold, execAsap) {
    var timeout;
    return function debounced() {
      var obj = this,
        args = arguments;
      function delayed() {
        if (!execAsap) func.apply(obj, args);
        timeout = null;
      }
      if (timeout) clearTimeout(timeout);else if (execAsap) func.apply(obj, args);
      timeout = setTimeout(delayed, threshold || 100);
    };
  };
  jQuery.fn[sr] = function (fn) {
    return fn ? this.bind("resize", debounce(fn)) : this.trigger(sr);
  };
})(jQuery, "smartresize");
function setCookie(c_name, value, exdays) {
  var exdate = new Date();
  exdate.setDate(exdate.getDate() + exdays);
  var c_value = escape(value) + (exdays == null ? "" : "; path=/; expires=" + exdate.toUTCString());
  document.cookie = c_name + "=" + c_value;
}
function getCookie(c_name) {
  var i,
    x,
    y,
    ARRcookies = document.cookie.split(";");
  for (i = 0; i < ARRcookies.length; i++) {
    x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="));
    y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1);
    x = x.replace(/^\s+|\s+$/g, "");
    if (x == c_name) {
      return unescape(y);
    }
  }
} // The flyout app adds a `cart` property with helper functions to this global object.

window.ONNIT = window.ONNIT || {}; // Open the flyout when the bag icon is clicked.

window.addEventListener('DOMContentLoaded', function () {
  var cartIcon = document.getElementById("cart-icon");
  if (cartIcon) {
    cartIcon.addEventListener("click", function (event) {
      event.preventDefault();
      if (!window.ONNIT || !window.ONNIT.cart) {
        throw new Error("Cannot open cart flyout drawer because `window.ONNIT.cart` is undefined.");
      }
      window.ONNIT.cart.openDrawer();
    });
  }
});
(function (window, document, $) {
  var $window = $(window),
    $document = $(document);
  ONNIT.imgpath = 'https://www.onnit.com';
  ONNIT.Authority = function (id, args) {
    var uri = '';
    var uri_args = ''; // this JS version is always going to work off the cdn since it has to know what authority id to serve

    args = args || {};
    args.full = true;
    if (typeof args !== 'undefined') {
      if (typeof args.full !== 'undefined' && args.full) {
        uri = ONNIT.imgpath.length ? ONNIT.imgpath : 'https://www.onnit.com';
        delete args.full;
      }
      if (typeof args.extension !== 'undefined') {
        id += args.extension;
        delete args.extension;
      } // default args

      args.auto = 'format';
      for (var i in args) {
        uri_args += '&' + i + '=' + args[i];
      }
    }
    return uri + '/onnit/images/authority/' + id + '.png?ver' + uri_args;
  }; // Example URL where this is used: /funnel/blah/new-mood/offer/

  ONNIT.dynamic_add_to_cart = function (event) {
    event.preventDefault();
    ONNIT.$dynamic_add_to_cart_form = $(event.target); // out of stock, dont try to add it.

    if (ONNIT.$dynamic_add_to_cart_form.hasClass('oos')) {
      return;
    }
    var $button = ONNIT.$dynamic_add_to_cart_form.find('.add-to-cart'); // update/check button state before proceeding

    if ($button.hasClass('adding')) {
      return;
    }
    $button.attr('data-og-text', $button.text()).prop('disabled', true).height($button.height()).toggleClass('adding').text('Adding...');
    /**
     *  there are a couple different input types in the wild that set frequency
     *  so we need some logic on whether or not to use the :checked attribute selector.
     */

    var frequencyField = ONNIT.$dynamic_add_to_cart_form.find('input[name="frequency"]');
    var subscription_interval = null;
    if (frequencyField && frequencyField.attr('type') === 'radio' || frequencyField.attr('type') === 'checkbox') {
      subscription_interval = ONNIT.$dynamic_add_to_cart_form.find('input[name="frequency"]:checked').val();
    } else {
      subscription_interval = ONNIT.$dynamic_add_to_cart_form.find('input[name="frequency"]').val();
    }
    var product_id = ONNIT.$dynamic_add_to_cart_form.find('input[name="products_id"]').val();
    var quantity = ONNIT.$dynamic_add_to_cart_form.find('input[name="cart_quantity"]').val();
    var is_trial_offer = $('input[name="trial_offer"]', ONNIT.$dynamic_add_to_cart_form).val() === "1";
    var email = $('input[name="email"]', ONNIT.$dynamic_add_to_cart_form).length > 0 ? $('input[name="email"]', ONNIT.$dynamic_add_to_cart_form).val() : null;
    var optin = !!(is_trial_offer && email && $('input[name="newsletter"]:checked', ONNIT.$dynamic_add_to_cart_form).length);
    var source = $('input[name="source"]', ONNIT.$dynamic_add_to_cart_form).val() ? $('input[name="source"]', ONNIT.$dynamic_add_to_cart_form).val() : '';
    var listName = $('input[name="list_name"]', ONNIT.$dynamic_add_to_cart_form).val() ? $('input[name="list_name"]', ONNIT.$dynamic_add_to_cart_form).val() : undefined;
    if (optin) {
      if (window.ONNIT.hubspot) {
        // Newsletter signup - Hubspot
        ONNIT.hubspot.emailListSignup(email, ['list_onnit_newsletter'], source);
      } else {
        console.warn("HubSpot: Failed to signup email because `ONNIT.hubspot` is undefined. Is JavaScript analytics enabled?");
      }
    }
    if (!window.ONNIT.cart) {
      throw new Error("Cannot add product to cart because `ONNIT.cart` is undefined.");
    }
    var doRedirectToCartPage = !!(is_trial_offer && email);
    var productConfig = {
      product_id: Number(product_id),
      quantity: Number(quantity),
      subscription_interval: Number(subscription_interval) || null
    };
    function resetButtonState() {
      $button.text($button.attr('data-og-text')).prop('disabled', false).removeClass('adding added').height('');
    }
    window.ONNIT.cart.addProducts([productConfig], function () {
      $button.toggleClass('adding added').text('Added');
      if (doRedirectToCartPage) {
        window.location.href = "/cart/";
        return;
      }
      window.ONNIT.cart.openDrawer();
      setTimeout(function () {
        resetButtonState();
      }, 2000);
    }, function (error) {
      resetButtonState();
      console.error(error);
    }, listName);
  };
  $('.js-inline-cart-form').submit(ONNIT.dynamic_add_to_cart);
  ONNIT.is_academy = $('body').attr('id') === 'blog';
  ONNIT.nav = {
    $a: $('#primary-nav li.has-secondary > a'),
    $a_acad: $('#academy-nav li.has-secondary > a, #certification-nav li.has-secondary > a'),
    $li: $('#primary-nav li.has-secondary'),
    $sub_a: $('#primary-nav a[data-src]'),
    state: null,
    site: null,
    version: null,
    shimmy: function shimmy() {
      // set data attribute on primary nav for screen size
      $('#primary-nav').attr('data-screen-size', window.IS_BIGSCREEN ? 'desktop' : 'mobile');
      if (!ONNIT.nav.state) {
        $document.on('click', ONNIT.nav.util_untoggle);
        $('.close-mobile-subnav').on('click', ONNIT.nav.sub_toggle);
        ONNIT.nav.version = $('#header').data('version');
        ONNIT.nav.site = $('#academy-nav').length ? 'academy' : 'onnit';
        ONNIT.nav.util_hover();
        /**
         * Utility Nav ADA
         */

        $('.site-util-primary-item > a').on('keydown', function (e) {
          if (e.keyCode === 32 || e.keyCode === 40) {
            var self = $(this);
            var parent = self.parents('.site-util-primary-item'); // Close all others

            $('.site-util-primary-item').not(parent).removeClass('is-open').prop('aria-expanded', false); // Open target

            self.parents('.site-util-primary-item').toggleClass('is-open').prop('aria-expanded', true); // Focus first contained element

            if (parent.hasClass('search')) {
              parent.find('#utilisearch-container input').focus();
            } else if (parent.hasClass('account')) {
              parent.find('.site-util-secondary-item:visible > a').eq(0).focus();
            }
            return false;
          }
        });
      } // Fix for mfp whackness

      $('body').on('click', '.mfp-content #utilaccount .mfp-close', function () {
        setTimeout(function () {
          $('#utilaccount').removeClass('mfp-hide');
        }, 500);
      });
      if (window.IS_BIGSCREEN) {
        if (ONNIT.nav.state === 'bigscreen') return;
        ONNIT.nav.$a.off('click');
        ONNIT.nav.$a_acad.off('click');
        ONNIT.nav.$li.on('mouseenter', ONNIT.nav.show_first_product);
        ONNIT.nav.$sub_a.on('mouseenter', ONNIT.nav.show_product);
        ONNIT.nav.state = 'bigscreen';
      } else {
        if (ONNIT.nav.state === 'smallscreen' || ONNIT.nav.$a.hasClass('event-handler-attached')) return;
        ONNIT.nav.$a.on('click', ONNIT.nav.sub_toggle).first().addClass('event-handler-attached');
        ONNIT.nav.$a_acad.on('click', ONNIT.nav.sub_toggle);
        ONNIT.nav.$sub_a.off('mouseenter');
        ONNIT.nav.state = 'smallscreen';
      }
    },
    util_untoggle: function util_untoggle(event) {
      if (!$(event.target).closest('.site-util-primary-item').length) {
        $('.site-util-primary-item').removeClass('is-open');
      }
    },
    show_product: function show_product(event, $link) {
      var $link = $link || $(event.currentTarget),
        _src = $link.data('src'),
        _blurb = $link.data('blurb'),
        _stage = $link.data('stage') || null,
        $stage = $link.closest('.site-nav-list-inner').find('.site-nav-stage');
      if (_stage) $stage = $stage.filterByData('stage', _stage);
      $stage.find('img').attr('src', _src);
    },
    show_first_product: function show_first_product(event) {
      var $li = $(event.currentTarget);
      if (ONNIT.nav.version === 'new') {
        var $first_link = $li.find('.site-nav-secondary-item:not(.site-nav-back-item) .site-nav-secondary-anchor:not(.close-mobile-subnav)');
        ONNIT.nav.show_product(null, $first_link);
      } else {
        var $img = $li.find('img[data-src]');
        $img.each(function (index, el) {
          $(el).attr('src', $(el).attr('data-src'));
        });
      }
    },
    sub_toggle: function sub_toggle(event) {
      event.preventDefault();
      var parent = $(event.target).closest('li.site-nav-primary-item'); // Close mobile subnav and select parent li

      if (parent.hasClass('toggled-on')) {
        $(event.target).closest('li.site-nav-primary-item');
        parent.find('a.site-nav-primary-anchor').attr('aria-expanded', false);
        parent.removeClass('toggled-on');
      } else {
        // Open mobile subnav and select first child li
        ONNIT.nav.$a.removeClass('event-handler-attached');
        $(event.target).closest('li.site-nav-primary-item');
        $(parent).find('a.site-nav-primary-anchor').attr('aria-expanded', true);
        parent.addClass('toggled-on tab-focus');
      }
    },
    util_hover: function util_hover() {
      // Do a one-time check to see if the customer is authenticated to determine what links to show in the account menu.
      $('.site-header-util').one('mouseenter click keydown', '.site-util-primary-item', function () {
        $.getJSON('/cart/cart-api.php?action=getAuthenticatedCustomer', function (responseBody) {
          var accountMenu = $('#utilaccount');
          var customerData = responseBody.customer;
          if (customerData.customer_id) {
            accountMenu.attr('data-state', 'logged-in');
            $('.new-your-account').attr('href', '/cart/account.php');
            if (window.ONNIT && window.ONNIT.googleTagManager) {
              window.ONNIT.googleTagManager.userSession(customerData.customer_id, customerData.email, customerData.email_hash, customerData.first_name, customerData.last_name);
            }
          } else {
            accountMenu.attr('data-state', 'logged-out');
          }
        });
      });
    }
  };
  ONNIT.nav.shimmy();
  $window.smartresize(function () {
    $window.trigger('smartresize-callback');
    var is_bigscreen_cached = window.IS_BIGSCREEN;
    if (typeof window.BIGSCREEN_TEST !== 'undefined') {
      window.BIGSCREEN_TEST();
    }
    if (window.IS_BIGSCREEN !== is_bigscreen_cached) {
      $('.mfp-close').trigger('click');
      $window.trigger('screen-change');
    }
    ONNIT.nav.shimmy();
  });
  ONNIT.header_ticker = {
    timer: null,
    init: function init() {
      ONNIT.header_ticker.timer = window.setInterval(ONNIT.header_ticker.rotate, 300);
    },
    stop: function stop() {}
  };
  ONNIT.popup_message = function (title, message, notify) {
    notify = notify || 'popup'; // 'notify' = 'popup' or the '.selector-string' for the div to update

    if (notify === 'popup') {
      $.magnificPopup.open({
        items: {
          src: '<div class="white-popup"><h2>' + title + '</h2><p>' + message + '</p></div>',
          type: 'inline'
        }
      });
    } else {
      var $notify = $(notify);
      if ($notify.length) {
        $notify.html('<b style="text-transform:uppercase;">' + title + '</b>&emsp;' + message);
      }
    }
  }; // Cycles through messages at the top of the global header (e.g. FHS, Friendbuy, Onnit X)

  ONNIT.cycle_header_messages = {
    timerInterval: 5000,
    timer: null,
    rotations: 0,
    header: null,
    maxRotations: 6,
    currentIndex: -1,
    itemCount: null,
    indicesDisplayed: [],
    init: function init() {
      var $header = ONNIT.cycle_header_messages.header = $('#global-header');
      $header.data('visibleIndex', 0);
      if ($('.global-header-item', $header).length > 0) {
        // Show the first header.
        ONNIT.cycle_header_messages.itemCount = $('.global-header-item', $header).length;
        ONNIT.cycle_header_messages.currentIndex = 0;
        if (getCookie('grm') === 'hellofriend') {
          $("#friendbuy-global-header").remove();
        }
        var headerElement = $('.global-header-item', $header)[0];
        headerElement.style.visibility = 'visible';
        ONNIT.cycle_header_messages.onHeaderDisplayed(headerElement, 0);
      }
    },
    rotate: function rotate() {
      if (ONNIT.cycle_header_messages.currentIndex >= 0) {
        // The first header is shown by default, so wait the number of interval seconds before showing each successive header.
        ONNIT.cycle_header_messages.timer = setInterval(function () {
          ONNIT.cycle_header_messages.start();
        }, ONNIT.cycle_header_messages.timerInterval);
      }
    },
    prev: function prev() {
      clearInterval(ONNIT.cycle_header_messages.timer);
      var header = ONNIT.cycle_header_messages.header;
      var $headerItems = $('.global-header-item', header);
      $headerItems[ONNIT.cycle_header_messages.currentIndex].style.visibility = 'hidden';
      ONNIT.cycle_header_messages.currentIndex--;
      if (ONNIT.cycle_header_messages.currentIndex < 0) {
        ONNIT.cycle_header_messages.currentIndex = 0;
      }
      $headerItems[ONNIT.cycle_header_messages.currentIndex].style.visibility = 'visible';
      $(".global-header-item a:visible").focus();
    },
    next: function next() {
      clearInterval(ONNIT.cycle_header_messages.timer);
      var header = ONNIT.cycle_header_messages.header;
      var $headerItems = $('.global-header-item', header);
      $headerItems[ONNIT.cycle_header_messages.currentIndex].style.visibility = 'hidden';
      ONNIT.cycle_header_messages.currentIndex++;
      if (ONNIT.cycle_header_messages.currentIndex >= ONNIT.cycle_header_messages.itemCount) {
        ONNIT.cycle_header_messages.currentIndex = ONNIT.cycle_header_messages.itemCount - 1;
      }
      $headerItems[ONNIT.cycle_header_messages.currentIndex].style.visibility = 'visible';
      $(".global-header-item a:visible").focus();
    },
    start: function start() {
      var header = ONNIT.cycle_header_messages.header;
      var $headerItems = $('.global-header-item', header),
        visibleIndex = header.data('visibleIndex'),
        nextVisibleIndex = visibleIndex < $headerItems.length - 1 ? visibleIndex + 1 : 0;
      header.data('visibleIndex', nextVisibleIndex);
      ONNIT.cycle_header_messages.currentIndex = nextVisibleIndex;
      if (visibleIndex === nextVisibleIndex || $headerItems.length === 0) {
        return;
      }
      $headerItems[visibleIndex].style.visibility = 'hidden';
      $headerItems[nextVisibleIndex].style.visibility = 'visible';
      ONNIT.cycle_header_messages.onHeaderDisplayed($headerItems[nextVisibleIndex], nextVisibleIndex);
      if (++ONNIT.cycle_header_messages.rotations >= ONNIT.cycle_header_messages.maxRotations) {
        ONNIT.cycle_header_messages.stop();
      }
    },
    stop: function stop() {
      ONNIT.cycle_header_messages.rotations = 0;
      clearInterval(ONNIT.cycle_header_messages.timer);
    },
    onHeaderDisplayed: function onHeaderDisplayed(headerElement, index) {
      // Push a promotion event once for each header displayed.
      if (ONNIT.cycle_header_messages.indicesDisplayed.includes(index)) {
        return;
      }
      ONNIT.cycle_header_messages.indicesDisplayed.push(index);
      try {
        var linkElement = $(headerElement).children('.global-header-item-link:first');
        var promotionData = linkElement.data('promotion');
        promotionData.position = index + 1;
        ONNIT.googleTagManager.promotionView([promotionData]);
      } catch (error) {
        console.error("Google Tag Manager: Failed to push global header promotion: %s", error);
      }
    }
  };
  ONNIT.use_experiment = function (experimentId, callback) {
    var count = 0,
      intervalId = setInterval(function () {
        if (window.google_optimize !== undefined) {
          clearInterval(intervalId);
          callback(window.google_optimize.get(experimentId));
        } else if (count++ > 20) {
          clearInterval(intervalId);
          callback(undefined);
        }
      }, 100);
  };
  var experiments = {};
  if (typeof experiments[window.location.pathname] !== 'undefined') {
    var $product_listing = $('.product-listing'),
      show_product_listing = function show_product_listing($product_listing) {
        $product_listing.removeClass('hide');
        $(window).trigger('resize');
      },
      sort_product_listing = function sort_product_listing($product_listing) {
        var $products = $product_listing.children('.product'),
          input_list = [];
        for (var i = 0, length = $products.length; i < length; i++) {
          input_list.push($products.eq(i).data('productId').toString());
        }
        ONNIT.personalize.getRanking(input_list, {
          success: function success(data) {
            try {
              var rankings = {};
              for (var i = 0, length = data.personalizedRanking.length; i < length; i++) {
                rankings[data.personalizedRanking[i].itemId] = i;
              }
              $products.detach().sort(function (a, b) {
                var $a = $(a),
                  aBaseRank = $a.data('outOfStock') === '' ? 1000 : 0,
                  aRank = aBaseRank + rankings[$a.data('productId').toString()],
                  $b = $(b),
                  bBaseRank = $b.data('outOfStock') === '' ? 1000 : 0,
                  bRank = bBaseRank + rankings[$b.data('productId').toString()];
                return aRank - bRank;
              });
              $product_listing.append($products);
              show_product_listing($product_listing);
            } catch (error) {
              show_product_listing($product_listing);
            }
          },
          error: function error() {
            show_product_listing($product_listing);
          }
        });
      };
    ONNIT.use_experiment(experiments[window.location.pathname], function (variant) {
      if (variant === '1' && $product_listing.length > 0) {
        try {
          sort_product_listing($product_listing);
        } catch (error) {
          console.error(error);
          show_product_listing($product_listing);
        }
      } else {
        show_product_listing($product_listing);
      }
    });
  }
})(window, document, jQuery);
/* global wrap for jquery when loaded in noconflict */

(function ($) {
  // simple plugin to bind callbacks to $.enter()
  $.fn.enter = function (callback) {
    return this.each(function () {
      $(this).keyup(function (e) {
        if (e.keyCode === 13 && typeof callback === 'function') {
          callback($(this));
        }
      });
    });
  }; // smooth scrolling

  $(function () {
    $('a[href*="#"]').not('.no-scroll').click(function () {
      if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
        var jQuerytarget = $(this.hash);
        if (!jQuerytarget.length && this.hash && this.hash.length > 1) {
          jQuerytarget = $('[name=' + this.hash.slice(1) + ']');
        }
        if (jQuerytarget.length) {
          var targetOffset = jQuerytarget.offset().top;
          if ($(this).attr('data-offset')) {
            targetOffset += parseInt($(this).attr('data-offset'));
          }
          $('html,body').animate({
            scrollTop: targetOffset
          }, 500);
          window.location.hash = this.hash;
          return false;
        }
      }
    });
  }); // all page onloads

  $(function () {
    function activate_inputs() {
      $('.input,.select,.textarea').not('.Input,.Select,.Textarea').each(function () {
        if ($(this).hasClass('is-activated')) return;
        if ($(this).val() || $('option:selected', this).text().toLowerCase() == 'please select') {
          $(this).parents('.input-group').addClass('is-dirty');
        } else {
          $(this).parents('.input-group').removeClass('is-dirty');
        }
      }).addClass('is-activated').on('focus', function () {
        if (!$(this).parents('.input-group').hasClass('is-read-only')) {
          $(this).parents('.input-group').addClass('is-focused');
        }
      }).on('change keyup', function () {
        if ($(this).val() || $('option:selected', this).text().toLowerCase() == 'please select') {
          $(this).parents('.input-group').addClass('is-dirty');
        } else {
          $(this).parents('.input-group').removeClass('is-dirty');
        }
      }).on('blur', function () {
        $(this).parents('.input-group').removeClass('is-focused');
      });
    }
    activate_inputs();
    $('.site-footer').on('click keydown', '.site-footer-expand-trigger', function (e) {
      // left click or enter key -- toggle
      // only toggle for mobile
      if ((e.which === 1 || e.keyCode === 13) && ONNIT.nav.state === "smallscreen") {
        $(this).toggleClass('is-expanded').next('.site-footer-expandable').toggleClass('is-expanded');
        var ariaExpanded = $(this).attr('aria-expanded');
        if (ariaExpanded !== 'undefined' && ariaExpanded) {
          if ($(this).hasClass('is-expanded')) {
            $(this).attr('aria-expanded', true);
          } else {
            $(this).attr('aria-expanded', false);
          }
        }
      }
    }); // Free shipping OUI toggle

    $("a[href$='free-shipping-details']").on('click keydown', function (e) {
      // left click or enter key -- toggle
      if (e.which === 1 || e.keyCode === 13) {
        e.preventDefault();
        $('.' + $(this).attr('href').substring(1)).toggleClass('hide');
      }
    }); // for search

    $('.site-search-form,.site-util-searchform').submit(function (event) {
      var q = $(this).find('input[name="q"]').val();
      if (q !== '') {
        if ($(this).attr('action') === '/search/') {
          window.location = '/search/?q=' + q;
        }
      } else {
        event.preventDefault();
        $(this).find('input[name="q"]').focus();
      }
    }); // imgix responive images

    if ((typeof imgix === "undefined" ? "undefined" : _typeof(imgix)) === 'object') imgix.onready(function () {
      imgix.fluid();
    }); // magnific popup calls

    $('.magnific-sidebar').magnificPopup({
      fixedContentPos: true,
      autoFocusLast: true,
      fixedBGPos: true,
      mainClass: 'mfp-sidebar mfp-sidebar-right mfp-wrap',
      removalDelay: 250,
      closeOnContentClick: false,
      showCloseBtn: true,
      callbacks: {
        open: function open() {},
        afterClose: function afterClose() {
          $('#userlike-tab').show();
          if ($('#product-page').length) {
            $('#product-page .buy-box .add-to-cart-button').trigger('cart-flyout-closed');
          }
        },
        change: function change() {
          // if clicking popup from sidebar, adjust the magnific wrap accordingly
          if (this.currItem.src == '#frequency-popup') {
            $(this.wrap).removeClass('mfp-sidebar mfp-sidebar-right');
          }
        }
      }
    });
    $('.magnific').each(function () {
      if ($(this).hasClass('mfp-event-handler-attached')) {
        return;
      }
      $(this).magnificPopup($(this).data('mfp-options')).addClass('mfp-event-handler-attached');
    });
    $('.utilicart-header-back').click(function () {
      $.magnificPopup.close();
    }); // make links external

    $('a[rel="external"]').attr('target', '_blank');
    /**
     * Site Newsletter Signup
     *
     * Most of the email list signup forms flow through this function.
     * This is now using the Hubspot JS SDK to send new prospects directly to Hubspot.
     *
     * @param event
     */

    var site_newsletter_signup = function site_newsletter_signup(event) {
      event.preventDefault();
      var $form = $(event.currentTarget);
      if ($form.hasClass('loading')) {
        return;
      }
      var action = $form.attr('action');
      var email = $form.find('input[type="email"]').val();
      var notify = typeof $form.attr('data-notify') !== 'undefined' ? $form.attr('data-notify') : 'popup';
      var close_me_after = typeof $form.attr('data-close-me-after') !== 'undefined' ? $form.attr('data-close-me-after') : null;
      var ob_api_track = typeof $form.attr('data-ob-api-track') !== 'undefined';
      $form.addClass('loading'); // Basic email validation.
      // empty string, missing @ sign, or @ sign at the beginning of the string are all considered invalid.

      if (!email.length || !email.indexOf('@')) {
        $form.removeClass('loading');
        ONNIT.popup_message('Error', 'Please enter a valid email', notify);
        return;
      } // Take the form data and reduce it into a data structure that is actually useful.

      var data = $form.serializeArray().reduce(function (accumulator, field) {
        accumulator[field.name] = field.value;
        return accumulator;
      }, {}); // These list names should stay in sync with EmailListsEnum in atlas-shared

      var lists = {
        onnit: 'list_onnit_newsletter',
        marvel: 'list_page_marvel',
        kettlebells: 'list_page_kettlebells',
        steelmace: 'list_page_steel_mace'
      }; // Default to standard onnit list/source if we couldn't figure it out from the form data

      var list = data.list && lists[data.list] ? lists[data.list] : lists.onnit;
      var source = data.source ? data.source : 'onnit:newsletter';
      if (!window.ONNIT.hubspot) {
        throw new Error("HubSpot: Failed to signup email because `ONNIT.hubspot` is undefined. Is JavaScript analytics enabled?");
      } // Send to Hubspot!

      var result = ONNIT.hubspot.emailListSignup(email, list, source);
      if (!result) {
        $form.removeClass('loading');
        ONNIT.popup_message('Error', 'Something went wrong. Try again in a few minutes.', notify);
        return;
      } // Show the popup success message, unless the form specifies we should suppress it.

      if (!$form.hasClass('suppress-success-popup')) {
        ONNIT.popup_message('Success', 'Check your inbox for a signup confirmation link from Onnit.', notify);
      }
      $form.removeClass('loading');
      $form.trigger('emailaccepted');
      if (close_me_after) {
        setTimeout(function () {
          $(close_me_after).fadeOut();
        }, 600);
      }
    };
    $('body').on('submit', '.site-newsletter-form', site_newsletter_signup); // HOTFIX: Attempt to load the primalday header image when Blazy is missing
  });

  /***
   * Onclick and Keyup are both necessary for ADA
   */
  $(".magnific-close-btn").click(function (e) {
    e.preventDefault();
    jQuery.magnificPopup.close();
    return false;
  });
  $(".magnific-close-btn").keyup(function (e) {
    if (e.keyCode(13)) {
      $(".magnific-close-btn").click();
    }
  });
})(jQuery);